let sentryModule = null;

export const getSentryModule = async () => {
  if (sentryModule) {
    return new Promise((resolve) => {
      resolve(sentryModule);
    });
  }

  return import('@sentry/nextjs').then((module) => {
    sentryModule = module;
    return module;
  });
};
