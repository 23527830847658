import Auth from '@/modules/Auth';
import sessionStorageService from './sessionStorageService';

export const getCommunityIdFromSessionStorage = () => {
  try {
    // Read JSON string from session storage
    const activeCommunityString =
      sessionStorageService.getItem('activeCommunity');
    if (!activeCommunityString) return '';

    // Convert JSON string to JSON object
    const activeCommuntiy = JSON.parse(activeCommunityString);

    return activeCommuntiy._id;
  } catch (e) {
    return '';
  }
};

export const getLoggedInUserEmailFromStorage = () => {
  try {
    // Read JSON string from session storage
    const userData = Auth.getUserDataSession();

    return userData.email;
  } catch (e) {
    return '';
  }
};
