import { trackGAEventWithCommunityAndUser } from '@/utility/analytics';
import classNames from 'classnames';
import React from 'react';
import Icon from './Icon';
import type { IconPath } from './Icon';
import NplToolTip from '../common/NplToolTip';
import { getSentryModule } from '@/utility/sentryService';
import Link from 'next/link';

export const BTN_HIERARCHY = {
  ACCENT_PRIMARY: 'Accent Primary',
  OUTLINE: 'Outline',
  NEUTRAL_PRIMARY: 'Neutral Primary',
  PLAIN: 'Plain',
  DESTRUCTIVE: 'Destructive',
  DESTRUCTIVE_OUTLINE: 'Destructive Outline',
  DESTRUCTIVE_PLAIN: 'Destructive Plain',
  SUCCESS_OUTLINE: 'Success Outline',
  SUCCESS: 'Success',
  SUCCESS_PLAIN: 'Success Plain',
  NEUTRAL_SECONDARY: 'Neutral Secondary'
};

const withTooltip = (button: React.ReactNode, tooltipText: string) => {
  if (tooltipText) {
    return (
      <NplToolTip text={tooltipText} closeToolTipOnClick>
        {button}
      </NplToolTip>
    );
  }
  return button;
};

const getBtnPaddingClassNames = (size, rounded) => {
  let padClassName = '';

  if (rounded) return '';

  switch (size) {
    case 'xs':
      padClassName = 'px-8';
      break;
    case 'sm':
      padClassName = 'px-12';
      break;
    case 'md':
      padClassName = 'px-12';
      break;
    case 'lg':
      padClassName = 'px-16';
      break;
    case 'xl':
      padClassName = 'px-16';
      break;
    default:
      padClassName = 'px-12';
      break;
  }

  return padClassName;
};

const getSizeClassName = (size, rounded) => {
  let sizeClassName = '';
  switch (size) {
    case 'xs':
    case 'sm':
      sizeClassName = classNames('h-32', { 'w-32': rounded });
      break;
    case 'md':
      sizeClassName = classNames('h-40', { 'w-40': rounded });
      break;
    case 'lg':
      sizeClassName = classNames('h-48', { 'w-48': rounded });
      break;
    case 'xl':
      sizeClassName = classNames('h-56', { 'w-56': rounded });
      break;
    default:
      // DEFAULT: md
      sizeClassName = classNames('h-40', { 'w-40': rounded });
      break;
  }

  return sizeClassName;
};

const getTextPaddingClassName = (size) => {
  return size === 'xl' ? 'px-8' : 'px-4';
};

const getBgColorClassName = (hierarchy, darkMode) => {
  let bgColorClassNames = '';

  switch (hierarchy) {
    case BTN_HIERARCHY.ACCENT_PRIMARY: {
      if (darkMode) {
        bgColorClassNames =
          'bg-npl-yellow-dark-solid-9  active:enabled:bg-bg-npl-yellow-light-solid-6 disabled:bg-npl-yellow-dark-solid-6';
      } else {
        bgColorClassNames =
          'bg-npl-yellow-light-solid-9 hover:enabled:bg-npl-yellow-light-solid-10 active:enabled:bg-[#D3A200] disabled:bg-npl-yellow-light-solid-6';
      }

      return bgColorClassNames;
    }
    case BTN_HIERARCHY.OUTLINE: {
      // TODO: @aman - dark mode case pending
      if (darkMode) {
        bgColorClassNames =
          'border-1 border-npl-neutral-dark-solid-7 hover:enabled:border-npl-neutral-dark-solid-8 hover:enabled:bg-npl-neutral-dark-solid-4 active:enabled:bg-npl-neutral-light-solid-5 disabled:border-npl-neutral-light-solid-6';
      } else {
        bgColorClassNames =
          'mix-blend-multiply hover:enabled:bg-npl-neutral-light-solid-3 border-1 border-npl-neutral-light-solid-7 hover:enabled:border-npl-neutral-light-solid-8 active:enabled:bg-npl-neutral-light-solid-4 active:enabled:border-npl-neutral-light-solid-8 disabled:border-npl-neutral-light-solid-6 bg-white-default';
      }
      return bgColorClassNames;
    }
    case BTN_HIERARCHY.NEUTRAL_PRIMARY: {
      if (darkMode) {
        bgColorClassNames =
          'bg-npl-neutral-light-solid-1 hover:enabled:bg-npl-neutral-light-solid-4 active:enabled:bg-npl-neutral-light-solid-4 disabled:bg-npl-neutral-light-solid-10';
      } else {
        bgColorClassNames =
          'bg-npl-neutral-dark-solid-3 hover:enabled:bg-npl-neutral-dark-solid-6 active:enabled:bg-npl-neutral-dark-solid-7 disabled:bg-npl-neutral-dark-solid-10';
      }
      return bgColorClassNames;
    }
    case BTN_HIERARCHY.PLAIN:
    case BTN_HIERARCHY.SUCCESS_PLAIN:
    case BTN_HIERARCHY.DESTRUCTIVE_PLAIN: {
      if (darkMode) {
        bgColorClassNames =
          'hover:enabled:bg-npl-neutral-dark-solid-4 active:enabled:bg-npl-neutral-dark-solid-5';
      } else {
        bgColorClassNames =
          'hover:enabled:bg-npl-neutral-light-solid-3 active:enabled:bg-npl-neutral-light-solid-4';
      }
      return bgColorClassNames;
    }
    case BTN_HIERARCHY.DESTRUCTIVE: {
      if (darkMode) {
        bgColorClassNames =
          'bg-npl-error-light-9 hover:enabled:bg-npl-error-light-10 active:enabled:bg-npl-error-light-11 disabled:bg-npl-error-light-6';
      } else {
        bgColorClassNames =
          'bg-npl-error-dark-9 hover:enabled:bg-npl-error-dark-10 active:enabled:bg-npl-error-dark-11 disabled:bg-npl-error-dark-6';
      }
      return bgColorClassNames;
    }
    case BTN_HIERARCHY.DESTRUCTIVE_OUTLINE: {
      if (darkMode) {
        bgColorClassNames =
          'border-1 border-npl-error-dark-7 hover:enabled:bg-npl-error-dark-3 hover:enabled:border-npl-error-dark-8 active:enabled:bg-npl-neutral-dark-solid-4 active:enabled:border-npl-neutral-dark-solid-8 disabled:border-npl-error-dark-6';
      } else {
        bgColorClassNames =
          'border-1 border-npl-error-light-7 hover:enabled:bg-npl-error-light-3 hover:enabled:border-npl-error-light-8 active:enabled:bg-npl-neutral-light-solid-4 active:enabled:border-npl-neutral-light-solid-8 disabled:border-npl-error-light-6';
      }
      return bgColorClassNames;
    }
    case BTN_HIERARCHY.SUCCESS_OUTLINE: {
      if (darkMode) {
        bgColorClassNames =
          'border-1 border-npl-succes-dark-7 hover:enabled:bg-npl-succes-dark-3 hover:enabled:border-npl-success-dark-8 active:enabled:bg-npl-succes-dark-4 active:enabled:border-npl-succes-dark-8 disabled:border-npl-success-dark-6';
      } else {
        bgColorClassNames =
          'border-1 border-npl-success-light-7 hover:enabled:bg-npl-success-light-3 hover:enabled:border-npl-success-light-8 active:enabled:bg-npl-success-light-4 active:enabled:border-npl-success-light-8 disabled:border-npl-success-light-6';
      }
      return bgColorClassNames;
    }
    case BTN_HIERARCHY.SUCCESS: {
      if (darkMode) {
        bgColorClassNames =
          'bg-npl-success-dark-9 hover:enabled:bg-npl-success-dark-10 active:enabled:bg-npl-success-dark-10 active:enabled:border-npl-success-dark-11 disabled:bg-npl-success-dark-6';
      } else {
        bgColorClassNames =
          'bg-npl-success-light-9 hover:enabled:bg-npl-success-light-10 active:enabled:bg-npl-success-light-10 active:enabled:border-npl-success-light-11 disabled:bg-npl-success-light-6';
      }
      return bgColorClassNames;
    }
    case BTN_HIERARCHY.NEUTRAL_SECONDARY: {
      if (darkMode) {
        return 'bg-npl-neutral-dark-alpha-3 hover:bg-npl-neutral-dark-alpha-4 active:hover:bg-npl-neutral-dark-alpha-5';
      }
      return 'bg-npl-neutral-light-alpha-3 hover:bg-npl-neutral-light-alpha-4 active:hover:bg-npl-neutral-light-alpha-5';
    }
    default:
      return '';
  }
};

const getShadowClassName = (hierarchy) => {
  switch (hierarchy) {
    case BTN_HIERARCHY.ACCENT_PRIMARY:
    case BTN_HIERARCHY.OUTLINE:
    case BTN_HIERARCHY.NEUTRAL_PRIMARY:
    case BTN_HIERARCHY.DESTRUCTIVE:
    case BTN_HIERARCHY.DESTRUCTIVE_OUTLINE:
      return 'shadow-npl-styles-button-shadow';
    default:
      return '';
  }
};

const getTextMetadataClassNames = (
  size,
  hierarchy,
  darkMode,
  disabled,
  isSecondary
) => {
  let textClassNames = '';
  let textSizeAndWeightClassNames = '';

  switch (size) {
    case 'xs':
      textSizeAndWeightClassNames = 'text-button-sm font-medium';
      break;
    case 'sm':
      textSizeAndWeightClassNames = 'text-button-md font-medium';
      break;
    case 'md':
      textSizeAndWeightClassNames = 'text-button-md font-medium';
      break;
    case 'lg':
      textSizeAndWeightClassNames = 'text-button-lg font-medium';
      break;
    case 'xl':
      textSizeAndWeightClassNames = 'text-button-lg font-medium';
      break;
    default:
      // DEFAULT: md
      textSizeAndWeightClassNames = 'text-button-md font-medium';
      break;
  }

  // color
  let textColorClassName = '';
  switch (hierarchy) {
    case BTN_HIERARCHY.ACCENT_PRIMARY:
      if (darkMode) {
        textColorClassName = disabled
          ? 'text-npl-yellow-dark-solid-8'
          : 'text-dark-1b';
      } else {
        textColorClassName = disabled
          ? 'text-npl-text-icon-on-light-surface-tertiary'
          : 'text-dark-1b';
      }

      break;
    case BTN_HIERARCHY.OUTLINE:
      if (darkMode) {
        textColorClassName = disabled
          ? 'text-npl-neutral-dark-solid-8'
          : 'text-npl-text-icon-on-dark-primary';
      } else {
        textColorClassName = disabled
          ? 'text-npl-neutral-light-solid-8'
          : 'text-dark-1b';
      }
      break;
    case BTN_HIERARCHY.NEUTRAL_PRIMARY: {
      if (darkMode) {
        textColorClassName = disabled
          ? 'text-npl-neutral-dark-solid-8'
          : 'text-dark-1b';
      } else {
        textColorClassName = disabled
          ? 'text-npl-neutral-light-solid-8'
          : 'text-npl-text-icon-on-dark-primary';
      }
      break;
    }
    case BTN_HIERARCHY.NEUTRAL_SECONDARY:
      if (darkMode) {
        textColorClassName = 'text-npl-text-primary-on-dark';
      } else {
        textColorClassName = 'text-npl-text-primary-on-light';
      }
      break;
    case BTN_HIERARCHY.PLAIN: {
      if (darkMode) {
        textColorClassName = disabled
          ? 'text-npl-neutral-dark-solid-8'
          : 'text-dark-1b';
      } else {
        // CHECK WITH DESIGNER ON ISSECONDARY @TRENT
        textColorClassName = disabled
          ? 'text-npl-neutral-light-solid-8'
          : isSecondary
            ? 'text-npl-text-icon-on-light-surface-secondary'
            : 'text-npl-text-icon-on-light-surface-primary';
      }
      break;
    }

    case BTN_HIERARCHY.DESTRUCTIVE: {
      // same for light and dark mode
      textColorClassName = disabled
        ? 'text-npl-neutral-dark-solid-8'
        : 'text-npl-text-icon-on-dark-primary';
      break;
    }
    case BTN_HIERARCHY.DESTRUCTIVE_OUTLINE: {
      if (darkMode) {
        textColorClassName = disabled
          ? 'text-npl-neutral-dark-solid-8'
          : 'text-npl-text-icon-on-dark-primary';
        break;
      } else {
        textColorClassName = disabled
          ? 'text-npl-neutral-light-solid-8'
          : 'text-dark-1b';
      }
      break;
    }

    case BTN_HIERARCHY.SUCCESS_OUTLINE: {
      if (darkMode) {
        textColorClassName = disabled
          ? 'text-npl-neutral-dark-solid-8'
          : 'text-npl-text-icon-on-dark-primary';
        break;
      } else {
        textColorClassName = disabled
          ? 'text-npl-neutral-light-solid-8'
          : 'text-npl-text-icon-on-light-surface-primary';
      }
      break;
    }

    case BTN_HIERARCHY.SUCCESS: {
      textColorClassName = 'text-npl-text-icon-on-dark-primary';
      break;
    }

    case BTN_HIERARCHY.SUCCESS_PLAIN: {
      textColorClassName = 'text-npl-success-light-11';
      break;
    }

    case BTN_HIERARCHY.DESTRUCTIVE_PLAIN: {
      textColorClassName = 'text-npl-error-light-11';
      break;
    }

    default:
      textColorClassName = '';
      break;
  }

  textClassNames = classNames(
    textSizeAndWeightClassNames,
    textColorClassName
  );

  return textClassNames;
};

const getIconFillColor = (
  hierarchy: string,
  darkMode: boolean,
  disabled: boolean,
  isSecondary = false
) => {
  if (darkMode) {
    switch (hierarchy) {
      case BTN_HIERARCHY.ACCENT_PRIMARY:
        return disabled ? '#7A5600' : '#1B1B18';
      case BTN_HIERARCHY.NEUTRAL_PRIMARY:
        return disabled ? '#52514C' : '#1B1B18';
      case BTN_HIERARCHY.PLAIN:
        return disabled ? '#52514C' : '#ADACAC';
      case BTN_HIERARCHY.OUTLINE:
        return disabled ? '#52514C' : '#ADACAC';
      case BTN_HIERARCHY.DESTRUCTIVE:
        return disabled ? '#F5F5F580' : '#ADACAC';
      case BTN_HIERARCHY.SUCCESS_PLAIN:
        return '#18774C';
      case BTN_HIERARCHY.NEUTRAL_SECONDARY:
        return disabled ? '#F5F5F5' : '#C8C7C1';
      case BTN_HIERARCHY.DESTRUCTIVE_PLAIN:
        return '#CE2C31';
      default:
        return '#ADACAC';
    }
  }

  switch (hierarchy) {
    case BTN_HIERARCHY.NEUTRAL_SECONDARY:
      return disabled ? '#52514C' : '#1B1B18';
    case BTN_HIERARCHY.NEUTRAL_PRIMARY:
      return disabled ? '#F5F5F5' : '#C8C7C1';
    case BTN_HIERARCHY.DESTRUCTIVE:
      return '#F5F5F5';
    // TODO @TRENT CHECK WITH DESIGNERS ON SECONDARY COLORS FOR PLAIN BUTTON
    case BTN_HIERARCHY.ACCENT_PRIMARY:
      return disabled ? '#C8C7C1' : '#1B1B18';
    case BTN_HIERARCHY.PLAIN:
      return disabled ? '#C8C7C1' : isSecondary ? '#1B1B18A6' : '#1B1B18';
    case BTN_HIERARCHY.OUTLINE:
      return disabled ? '#C8C7C1' : '#1B1B18';
    case BTN_HIERARCHY.SUCCESS_PLAIN:
      return '#18774C';
    case BTN_HIERARCHY.DESTRUCTIVE_PLAIN:
      return '#CE2C31';
    case BTN_HIERARCHY.SUCCESS:
      return '#FFFFFF';
    default:
      return '#1B1B18';
  }
};

/**
 * Button component with customizable properties.
 * @param {Object} props - Props for the Button component.
 */
type NPLButtonProps = {
  hierarchy: string;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  darkMode?: boolean;
  buttonText?: string;
  leadIcon?: string;
  tailIcon?: string;
  disabled?: boolean;
  iconWidth?: number;
  loading?: boolean;
  stretch?: boolean;
  rounded?: boolean;
  dataTestId?: string;
  isSecondary?: boolean;
  multiply?: boolean;
  tooltipText?: string;
  onClick?: (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLAnchorElement>
  ) => void;
  leadIconType?: IconPath;
  tailIconType?: IconPath;
  [key: string]: unknown; // for additional props
  link?: string;
};

const NPLButton: React.FC<NPLButtonProps> = ({
  hierarchy,
  size,
  darkMode = false,
  buttonText = '',
  leadIcon = '',
  iconWidth,
  tailIcon = '',
  disabled = false,
  loading = false,
  stretch = false,
  dataTestId = '',
  rounded = false, // set to true for icon only button
  multiply = false, // set to true if button is on a background
  isSecondary = false,
  onClick,
  leadIconType = 'common', // for NPLIcon lead icon type (common, social, payment_methods)
  tailIconType = 'common', // for NPLIcon lead icon type (common, social, payment_methods)
  tooltipText,
  link,
  ...btnProps // for datatest
}) => {
  const getClassNames = () => {
    let btnClassNames = classNames(
      `c-NPLButton relative flex justify-center items-center`,
      {
        'rounded-full': rounded,
        'rounded-100': !rounded,
        'mix-blend-multiply': multiply
      }
    );
    const btnStretchClassName = stretch ? 'w-full' : '';
    const btnPaddingClassNames = getBtnPaddingClassNames(size, rounded);
    const sizeClassName = getSizeClassName(size, rounded);
    const bgColorClassNames = getBgColorClassName(hierarchy, darkMode);
    const shadowClassNames = getShadowClassName(hierarchy);

    btnClassNames = classNames(
      btnClassNames,
      btnPaddingClassNames,
      btnStretchClassName,
      sizeClassName,
      bgColorClassNames,
      shadowClassNames
    );

    return btnClassNames;
  };

  const getTextClassNames = () => {
    return classNames(
      getTextPaddingClassName(size),
      getTextMetadataClassNames(
        size,
        hierarchy,
        darkMode,
        disabled,
        isSecondary
      ),
      'w-max'
    );
  };

  const getIconWidthAndHeight = () => {
    switch (size) {
      case 'xs':
      case 'sm':
        return { width: iconWidth || 16, height: 16 };
      case 'md':
        return { width: iconWidth || 20, height: 20 };
      case 'lg':
      case 'xl':
        return rounded
          ? { width: iconWidth || 24, height: 24 }
          : { width: iconWidth || 20, height: 20 };
      default:
        // DEFAULT: md
        return { width: 20, height: 20 };
    }
  };

  const renderIcon = (iconName, iconType) => {
    if (!iconName) return null;

    const widthAndHeight = getIconWidthAndHeight();
    const iconFillColor = getIconFillColor(
      hierarchy,
      darkMode,
      disabled,
      isSecondary
    );

    return (
      <Icon
        key={iconName}
        name={iconName}
        {...widthAndHeight}
        fill={iconFillColor}
        fillOpacity="1"
        path={iconType}
      />
    );
  };

  const renderLoadingOverlay = () => {
    if (!loading) return null;

    const widthAndHeight = getIconWidthAndHeight();
    const iconFillColor = getIconFillColor(hierarchy, darkMode, true); // 3 param of disabled is set to true

    return (
      <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center rounded-100">
        <div className="animate-[spin_2s_linear_infinite]">
          <Icon
            name="loading-01"
            {...widthAndHeight}
            fill={iconFillColor}
            fillOpacity="1"
          />
        </div>
      </div>
    );
  };

  const btnClassNames = getClassNames();
  const btnTextClassNames = getTextClassNames();

  const addSentryBreadcrumb = async () => {
    const sentry = (await getSentryModule()) as {
      addBreadcrumb: (breadcrumb: {
        category: string;
        message: string;
        level: string;
      }) => void;
    };
    typeof sentry.addBreadcrumb === 'function' &&
      sentry.addBreadcrumb({
        category: 'ui.click',
        message: `NPLButton clicked: ${buttonText} ${
          dataTestId ? ` | data-test-id: (${dataTestId})` : ''
        }`,
        level: 'info'
      });
  };

  const onClickMiddleware = (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLAnchorElement>
  ) => {
    // track click event
    trackGAEventWithCommunityAndUser('npl-button-clicked', {
      buttonProps: btnProps
    });

    addSentryBreadcrumb();

    // pass on to onClick prop
    if (onClick) {
      onClick(e);
    }
  };

  const element = link ? Link : 'button';

  // Common props
  const commonProps = {
    className: btnClassNames,
    onClick: onClickMiddleware
  };

  const buttonSpecificProps = {
    type: 'button',
    'data-test-id':
      dataTestId !== '' ? dataTestId : buttonText || 'npl-button',
    disabled: disabled || loading,
    ...btnProps
  };

  // Combine props based on the element type
  const elementProps =
    element === Link
      ? { ...commonProps, href: link }
      : { ...commonProps, ...buttonSpecificProps };

  // Ensure the props align with the element type
  // Without this, typescript is unsure if href is actually passed to Link component
  const Element = element as React.ElementType;

  return withTooltip(
    React.createElement(
      Element,
      elementProps,
      <div>
        <div
          className={classNames(
            'min-h-16 flex min-w-[16px] items-center justify-center space-x-4',
            {
              'opacity-0': loading
            }
          )}>
          {renderIcon(leadIcon, leadIconType)}
          {buttonText && <p className={btnTextClassNames}>{buttonText}</p>}
          {renderIcon(tailIcon, tailIconType)}
        </div>

        {renderLoadingOverlay()}
      </div>
    ),
    tooltipText
  );
};

export default NPLButton;
