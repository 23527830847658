import PropTypes from 'prop-types';
import * as Tooltip from '@radix-ui/react-tooltip';
import { useState } from 'react';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import Icon from '@/components/npl/Icon';
import PopOverModal from '../PopOverModal';

const NplToolTip = ({
  children,
  text,
  title = null,
  defaultOpenState = false,
  closeToolTipOnClick = false,
  wrapChildrenInButton = true
}) => {
  const [open, setOpen] = useState(defaultOpenState);
  const { isGtEqMd } = useWindowWidthContext();

  return isGtEqMd ? (
    <Tooltip.Provider>
      <Tooltip.Root open={open} delayDuration={150} onOpenChange={setOpen}>
        <Tooltip.Trigger asChild>
          {wrapChildrenInButton ? (
            <button
              className="cursor-default "
              onClick={(e) => {
                e.preventDefault(); // stop form submission if tooltip is in a form
                setOpen(true);
                if (closeToolTipOnClick) {
                  setOpen(false);
                }
              }}>
              {children}
            </button>
          ) : (
            children
          )}
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="leading-none text-wrap z-over-intercom max-w-[350px] select-none rounded-[8px] bg-npl-text-icon-on-light-surface-primary px-[12px] py-[8px] text-body-sm text-npl-text-icon-on-dark-primary shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity] data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade"
            sideOffset={5}>
            {text}
            <Tooltip.Arrow className="fill-npl-text-icon-on-light-surface-primary" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  ) : (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(true);
        }}>
        {children}
      </button>
      <PopOverModal
        showCloseIcon={false}
        open={open}
        onClose={() => setOpen(false)}>
        <div className="mx-auto">
          <div className="px flex h-32 items-center justify-center border-b border-npl-neutral-light-solid-4 pb-28 pt-12">
            <div className="flex-1 text-center text-label-lg font-semibold text-npl-text-icon-on-light-surface-primary ">
              {title}
            </div>
            <div
              onClick={() => setOpen(false)}
              className="flex h-32 w-32 items-center justify-center rounded-full hover:cursor-pointer hover:bg-npl-neutral-light-solid-3">
              <Icon
                name="x-close"
                width={16}
                height={16}
                fill="text-npl-text-icon-on-light-surface-tertiary"
              />
            </div>
          </div>
          <p className="pb-32 pt-12 text-body-md text-npl-text-icon-on-light-surface-primary">
            {text}
          </p>
        </div>
      </PopOverModal>
    </>
  );
};

NplToolTip.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func
  ])
};

export default NplToolTip;
