import { useCallback, useLayoutEffect, useState } from 'react';

import CloseIcon from '../Icons/CloseIcon';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import style from './PopOverModal.module.scss';
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import useAos from '@/hooks/useAos';
import Icon from '@/components/npl/Icon';
import NPLButton, { BTN_HIERARCHY } from '@/components/npl/NPLButton';

const sizesMap = {
  sm: 'max-w-300',
  md: 'max-w-500',
  lg: 'max-w-800',
  xl: 'max-w-1140',
  default: 'max-w-auto' //fallback
};

const PopOverModal = ({
  open = false,
  onClose,
  showCloseIcon = true,
  hideGutters = false,
  closeIconHoverText = '',
  closeOnBackdropClick = true,
  customBaseClass = '',
  customContainerClass = '',
  customInnerClass = '',
  customCloseIconClass = '',
  customEyebrowClass = '',
  children,
  size = 'default',
  withOutsideCloseIcon = false,
  showLeftIcon = false,
  leftIcon = null,
  containerBackgroundTransparent = '',
  customBackdropClassNames = '',
  chin = null,
  eyebrow = null,
  stickChinToBottom = false,
  showNasLogoAtTopLeft = false
}) => {
  useAos({
    duration: 500,
    ones: true
  });
  const className = 'c-PopOverModal';
  const [_document, setDocument] = useState(null);

  const baseClassNames = classNames(className, style[className], {
    [style[`${className}--open`]]: open
  });
  const backDropFilter = containerBackgroundTransparent
    ? 'backdrop-blur-lg	'
    : '';

  const containerClassNames = classNames(
    `${className}__container`,
    'animate-fadeInModal',
    {
      [style[`${className}__transparent_white_color`]]:
        !!containerBackgroundTransparent
    },
    style[`${className}__container`],
    { [style[`${className}__container__no-gutter`]]: hideGutters },
    sizesMap?.[size],
    {
      [customContainerClass]: !!customContainerClass
    },
    'flex flex-col items-stretch'
  );

  const innerClassNames = classNames(
    style[`${className}__inner`],
    {
      [customInnerClass]: !!customInnerClass
    },
    'flex-grow'
  );

  const backdropClassNames = classNames(
    style[`${className}__backdrop`],
    'backdrop-blur-sm transition duration-500',
    backDropFilter,
    customBackdropClassNames
  );

  const closeModalIconClassName = classNames(
    `${className}__close-modal`,
    style[`${className}__close-modal`],
    {
      [`${style[`${className}__outside-close-modal`]}`]:
        withOutsideCloseIcon,
      [customCloseIconClass]: !!customCloseIconClass
    }
  );

  const modalEl = _document?.getElementById('modal');

  const handleBackdropClick = () => {
    if (closeOnBackdropClick && !containerBackgroundTransparent) onClose();
  };

  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (document.body && open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (document.body)
        return (document.body.style.overflow =
          originalStyle === 'hidden' ? 'initial' : originalStyle);
    };
  }, [open]);

  // Access document in Next after page renders
  useLayoutEffect(() => {
    setDocument(document);
  }, []);

  const renderCloseIcon = useCallback(
    () =>
      showCloseIcon && (
        <div className={closeModalIconClassName} onClick={onClose}>
          {closeIconHoverText ? (
            <div className={style[`${className}__tooltip`]}>
              <span className={style[`${className}__tooltiptext`]}>
                {closeIconHoverText}
              </span>
              <CloseIcon />
            </div>
          ) : (
            <NPLButton
              hierarchy={BTN_HIERARCHY.PLAIN}
              leadIcon="x-close"
              rounded
              size="sm"
              isSecondary
            />
          )}
        </div>
      ),
    [closeIconHoverText, closeModalIconClassName, onClose, showCloseIcon]
  );

  const renderLeftIcon = useCallback(
    () =>
      showLeftIcon && (
        <div
          className={`${className}__left-icon ${
            style[`${className}__left-icon`]
          }`}>
          {leftIcon}
        </div>
      ),
    [showLeftIcon, leftIcon]
  );

  if (!modalEl) return null;
  return createPortal(
    <div
      className={`${baseClassNames} ${customBaseClass}`}
      data-testid="modal">
      <div className={backdropClassNames} onClick={handleBackdropClick} />
      {showNasLogoAtTopLeft && (
        <div className="fixed left-24 top-24">
          <Icon
            path="logo"
            name="nas-io-logo-with-text-grayscale"
            width={116}
            height={24}
          />
        </div>
      )}
      {withOutsideCloseIcon && renderCloseIcon()}
      <div className={`${containerClassNames} relative`}>
        {eyebrow && (
          <div
            className={classNames(
              `${className}__eyebrow`,
              style[`${className}__eyebrow`],
              customEyebrowClass
            )}>
            {eyebrow}
          </div>
        )}
        {showLeftIcon && renderLeftIcon()}
        {!withOutsideCloseIcon && renderCloseIcon()}

        <div className={`${innerClassNames}`}>{children}</div>
        {chin && (
          <div
            className={`${className}__chin  ${
              style[`${className}__chin`]
            } ${stickChinToBottom && 'absolute bottom-0 w-full'}`}>
            {chin}
          </div>
        )}
      </div>
    </div>,
    modalEl
  );
};

export default PopOverModal;
