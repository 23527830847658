export const HOMEPAGE_CTA_CLICK_EVENT = 'click_cta_homepage';
export const PRODUCT_DEMO_CTA_CLICK_EVENT = 'click_cta_product_demo';
export const NAVBAR_ITEM_CLICK_EVENT = 'click_navbar_link';
export const FOOTER_ITEM_CLICK_EVENT = 'click_footer_link';
export const HOMEPAGE_WATCH_VIDEO_EVENT = 'watch_home_video_click';
export const HOMEPAGE_WATCH_VIDEO_COMPLETED_EVENT = 'watched_home_video';
export const HOMEPAGE_WATCH_PRODUCT_DEMO_VIDEO_EVENT =
  'watch_home_product_demo_video_click';
export const HOMEPAGE_WATCH_PRODUCT_DEMO_VIDEO_COMPLETED_EVENT =
  'watched_home_product_demo_video';
export const HOMEPAGE_LEARN_MORE_EVENT = 'click_homepage_learn_more';

export const LOGOUT_EVENT = 'logout_success';
export const HOME_CREATE_ANNOUNCEMENT_EVENT = 'home_create_announcement';
export const HOME_CREATE_ANNOUNCEMENT_SUCCESS_EVENT =
  'home_create_announcement_success';
export const HOME_EDIT_ANNOUNCEMENT_EVENT = 'home_edit_announcement';
export const HOME_DELETE_ANNOUNCEMENT_EVENT = 'home_delete_annoucement';
export const HOME_PIN_ANNOUNCEMENT_EVENT = 'home_pin_announcement';
export const HOME_UNPIN_ANNOUNCEMENT_EVENT = 'home_unpin_announcement';
export const HOME_CLICKED_COPY_INVITE_LINK_EVENT =
  'clicked_copy_link_home';

export const EVENTS_ADD_EVENT = 'events_add_event';
export const EVENTS_ADD_SUCCESS_EVENT = 'events_add_event_success';
export const EVENTS_EDIT_EVENT = 'events_edit_event';
export const EVENTS_EDIT_SUCCESS_EVENT = 'events_edit_event_success';
export const EVENTS_DELETE_EVENT = 'events_delete_event';
export const EVENTS_DUPLICATE_EVENT = 'events_duplicate_event';
export const EVENTS_SHARE_EVENT = 'events_share';
export const EVENTS_CUSTOMIZE_EMAIL = 'events_customize_email';

export const LIB_ADD_COURSE_SUCCESS_EVENT = 'library_add_course_success';
export const LIB_ADD_COURSE_EVENT = 'library_add_course';
export const LIB_EDIT_COURSE_SUCCESS_EVENT = 'library_edit_course_details';
export const LIB_EDIT_COLLECTION_SUCCESS_EVENT =
  'library_edit_collection_details';
export const LIB_COURSE_PUBLISH_EVENT = 'library_course_publish';
export const LIB_MANAGE_COURSE_EVENT = 'library_manage_course_content';
export const LIB_DELETE_COURSE_EVENT = 'library_delete_course';
export const LIB_DELETE_COLLECTION_EVENT = 'library_delete_collection';
export const LIB_COURSE_ADD_VIDEO_EVENT = 'library_add_video';
export const LIB_COURSE_ADD_FILE_EVENT = 'library_add_file';
export const LIB_COURSE_DELETE_VIDEO_EVENT = 'library_delete_video';
export const LIB_COURSE_DELETE_FILE_EVENT = 'library_delete_file';
export const LIB_COURSE_SHARE_EVENT = 'library_course_share';
export const LIB_COLLECTION_SHARE_EVENT = 'library_collection_share';

export const CMP_LOGIN_EVENT = 'cmp_login_on_login_page';
export const MEMBER_LOGIN_EVENT = 'member_login_on_login_page';

export const CLICKED_MEMBER_VIEW_EVENT = 'clicked_member_view';
export const CLICKED_MANAGER_VIEW_EVENT = 'clicked_manager_view';
export const CLICKED_VIEW_PROFILE_EVENT = 'clicked_profile';

export const CLICKED_GETTING_STARTED_SIDENAV_EVENT =
  'clicked_getting_started';
export const CLICKED_HOME_SIDENAV_EVENT = 'clicked_home';
export const CLICKED_MEMBERS_SIDENAV_EVENT = 'clicked_members';
export const CLICKED_EVENTS_SIDENAV_EVENT = 'clicked_events';
export const CLICKED_ANALYTICS_SIDENAV_EVENT = 'clicked_analytics';
export const CLICKED_LIBRARY_SIDENAV_EVENT = 'clicked_library';
export const CLICKED_PUBLIC_PAGE_SIDENAV_EVENT = 'clicked_publicpage';
export const CLICKED_SETTINGS_SIDENAV_EVENT = 'clicked_settings';
export const CLICKED_MAGIC_REACH_SIDENAV_EVENT = 'clicked_magicreach';
export const CLICKED_CHAT_SIDENAV_EVENT = 'clicked_chat';
export const CLICKED_MONEY_PAGE_SIDENAV_EVENT = 'clicked_money_tab';
export const CLICKED_CHALLENGES_PAGE_SIDENAV_EVENT =
  'clicked_challenges_tab';
export const CLICKED_FEED_PAGE_SIDENAV_EVENT = 'clicked_feed_tab';
export const CHALLENGES_CUSTOMIZE_EMAIL = 'challenges_customize_email';

// price settings change
export const PRICE_SETTINGS_CHANGE = 'clicked_save_changes_pricing';
export const PRICE_SETTINGS_CONFIRM_CHANGES =
  'clicked_confirm_saved_changes_pricing';
export const CLICKED_EXPORT_MEMBERS_EVENT = 'clicked_export_member';

export const PUBLIC_PAGE_CHANGE_COMMUNITY_IMAGE_EVENT =
  'publicpage_change_community_image';
export const PUBLIC_PAGE_CHANGE_COMMUNITY_COVER_EVENT =
  'publicpage_change_community_cover';
export const PUBLIC_PAGE_CHANGE_COMMUNITY_TITLE_EVENT =
  'publicpage_change_community_title';
export const PUBLIC_PAGE_CHANGE_COMMUNITY_DESC_EVENT =
  'publicpage_change_community_description';
export const PUBLIC_PAGE_CHANGE_COMMUNITY_HOST_EVENT =
  'publicpage_change_community_host';
export const PUBLIC_PAGE_CHANGE_COMMUNITY_URL_EVENT =
  'publicpage_change_community_url';
export const PUBLIC_PAGE_VISIT_CLICK_EVENT = 'publicpage_visit_page';
export const PUBLIC_PAGE_COPY_LINK_EVENT = 'public_page_copy_link';

export const INVITE_MEMBER_BY_EMAIL_EVENT = 'invite_members_by_email';

export const MONEY_PAGE_CLICKED_CREATE_PAID_EVENT =
  'money_clicked_create_event';
export const MONEY_PAGE_CLICKED_OPEN_EDIT_MEMBERSHIP_MODAL =
  'money_clicked_paid_membership';
export const MONEY_PAGE_EDIT_COMMUNITY_PRICE_EVENT =
  'money_clicked_paid_membership_success';
export const MONEY_PAGE_CLICKED_PAYOUT_FORM = 'money_clicked_payout_form';
export const MONEY_PAGE_CLICKED_OPEN_EDIT_REVENUE_TARGET_MODAL =
  'money_clicked_edit_revenue_target';
export const MONEY_PAGE_EDIT_REVENUE_TARGET_SUCCESS_EVENT =
  'money_clicked_edit_revenue_target_success';
export const CHANGE_MEMBER_ACCESS_TYPE = 'settings_change_access_type';

export const TRACK_PRODUCT_DEMO_START_COMMUNITY_NAV =
  'product_demo_start_community_nav';

export const TRACK_PRODUCT_DEMO_START_COMMUNITY_TIMEOUT_MODAL =
  'product_demo_start_community_timeout_modal';
export const TRACK_PRODUCT_DEMO_START_COMMUNITY_ACTION_BLOCKED_MODAL =
  'product_demo_start_community_action_blocked_modal';
export const TRACK_PRODUCT_DEMO_PAGE_START_DEMO_CLICK =
  'product_demo_page_start_demo_buttn_click';

export const CONTACT_US_FORM_SUBMISSION = 'contact_us_form_submit';
export const SET_COMMUNITY_ACCESS_TYPE_EVENT = 'set_community_access_type';

export const CLICK_GET_EARLY_ACCESS_WHATSAPP = 'click_whatsapp_get_beta';
export const SUBMIT_GET_EARLY_ACCESS_WHATSAPP_SUCCESS =
  'submit_whatsapp_get_beta_success';

//---- Whatsapp Member Sign up analytics ------
export const INITIATE_CHECKOUT = 'initiate_checkout';
export const SIGNUP_SUCCESS = 'signup_success';
export const CHECKOUT_FILL_WHATSAPP_NUMBER =
  'checkout_fill_whatsapp_number';
//---- Whatsapp Member Sign up analytics ------

/*Referral events*/
export const VISIT_REFERRAL_LINK = 'visit_referral_link';

/*Event*/
export const COMPOSE_MESSAGE_TO_EVENT_ATTENDEES =
  'compose_message_to_event_attendees';
export const EVENT_SIGNUP_SUCCESS = 'register_event_via_public_page';

export const TRACK_ROUTE_CHANGE = 'route_change';

export const AI_SUMMARY_EVENTS = {
  VIEW: 'ai_summary_view_click',
  WHATSAPP_SHARE: 'ai_summary_wa_share',
  MR_SHARE: 'ai_summary_mr_share',
  COPY_SHARE: 'ai_summary_copy_share',
  LIKE_CLICK: 'ai_summary_like',
  DISLIKE_CLICK: 'ai_summary_dislike',
  DATE_CHANGE: 'ai_summary_change_date'
};

/*New home tab events*/
export const HOMETAB_EVENTS = {
  CREATE_BUTTON_CLICK: 'hp_create_click',
  CREATE__MAGIC_REACH_CLICK: 'hp_create_magic_reach_click',
  CREATE_EVENT_CLICK: 'hp_create_event_click',
  CREATE_CONTENT_CLICK: 'hp_create_content_click',
  CREATE_POST_CLICK: 'hp_create_post_click',
  CREATE_CHALLENGE_CLICK: 'hp_create_challenge_click',
  INVITE_MEMBER_CLICK: 'hp_invite_member_click',
  COPY_COMMUNITY_LINK: 'hp_copy_community_link_click',
  OPEN_COMMUNITY_LINK: 'hp_open_community_link_click',
  MEMBERS_CARD_CLICK: 'hp_members_card_click',
  EARNINGS_CARD_CLICK: 'hp_earnings_card_click',
  CHAT_CARD_CLICK: 'hp_chat_card_click'
};

/*New home tab activity events*/
export const ACTIVITIY_EVENTS = {
  SCROLLING_ACTIVITIES: 'hp_scrolling_activity_feed',
  SUBSCRIPTION_JOINED: 'hp_activity_subscription_joined_click',
  SUBSCRIPTION_PENDING_APPROVAL: 'hp_activity_pending_approval_click',
  SUBSCRIPTION_UNSUBSCRIBED: 'hp_activity_subscription_unsubscribed_click',
  EVENT_REGISTERED: 'hp_activity_event_registered_click',
  FOLDER_PURCHASED: 'hp_activity_folder_purchased_click',
  MILESTONE_COMMUNITY_CREATED:
    'hp_activity_milestone_community_created_click',
  MILESTONE_FIRST_SALE: 'hp_activity_milestone_first_sale_click',
  MILESTONE_FIRST_EVENT: 'hp_activity_milestone_first_event_click',
  MILESTONE_FIRST_CONTENT: 'hp_activity_milestone_first_content_click',
  MILESTONE_FIRST_POST: 'hp_activity_milestone_first_post_click',
  MILESTONE_ONE_DOLLAR_GIFT: 'hp_activity_milestone_one_dollar_gift',
  MILESTONE_FIVE_DOLLAR_GIFT: 'hp_activity_milestone_five_dollar_gift'
};

/*New home tab activity events*/
export const ANNOUNCEMENT_CARD_EVENTS = {
  PRIMARY_CTA_CLICK: 'hp_primary_cta_click',
  SECONDARY_CTA_CLICK: 'hp_secondary_cta_click',
  SEEN_ANNOUNCEMENT_CLICK: 'hp_seen_announcement_click'
};

export const CMP_SEE_COMMUNITY_EX_CLICK = 'cmp_see_community_ex_click';

// Products activity events
export const PRODUCT_ADD_PRODUCT_CLICK = 'product_add_product_click';
export const PRODUCT_TEMPLATE_CLICK = 'product_template_click';
export const PRODUCT_ADD_DIGITAL_PRODUCT = 'product_add_digital_product';
export const PRODUCT_ADD_SESSION = 'product_add_session';

// 1-1 Session activity events
export const SESSION_CLICKED = '11session_clicked';
export const SESSION_SHARE_SESSION = '11session_share';
export const SESSION_PUBLIC_PAGE_CLICK = '11session_view_public_page';
export const SESSION_EDIT = '11session_edit';
export const SESSION_DUPLICATE = '11session_duplicate';
export const SESSION_DOWNLOAD_BOOKINGS = '11session_download_bookings';
export const SESSION_DELETE = '11session_delete';
export const SESSION_BOOKINGS_TAB = '11session_bookings_tab';
export const SESSION_DETAILS_TAB = '11session_details_tab';
export const SESSION_BOOKINGS_FILTER = '11session_bookings_filter';
export const SESSION_VIEW_BOOKING = '11session_booking_view';
export const SESSION_CHECKOUT_SIDESHEET = '11session_checkout_sidesheet';
export const SESSION_AVAILABILITY_SIDESHEET =
  '11session_availability_sidesheet';
export const SESSION_HOST_SIDESHEET = '11sesWsion_host_sidesheet';
export const SESSION_EMAIL_SIDESHEET = '11session_email_sidesheet';
export const SESSION_ADD_BOOKING_QUESTION =
  '11session_add_booking_question';
export const SESSION_CHANGE_TIMEZONE = '11session_change_timezone';
export const SESSION_ADD_UNAVAILABLE_DATES =
  '11session_add_unavailable_dates';
export const SESSION_STOP_BOOKINGS = '11session_stop_bookings';
export const SESSION_CUSTOMIZE_EMAIL = '11session_customize_email';
export const SESSION_PUBLISH = '11session_publish';
export const SESSION_CANCEL_BOOKING = '11session_cancel_booking';
export const SESSION_CANCEL_AND_REFUND_BOOKING =
  '11session_cancel_and_refund_booking';

// New pass on take rate tracking event
export const PASS_ON_LEARN_MORE = 'pass_on_learn_more_click';
export const PASS_ON_CHANGE_WHO_PAYS_THE_FEES =
  'pass_on_choose_who_pays_the_fees';
export const PASS_ON_SEE_EXAMPLE = 'pass_on_see_example';
export const PASS_ON_HIDE_EXAMPLE = 'pass_on_hide_example';
export const PASS_ON_CONFIRM_CHANGE_WHO_PAYS_THE_FEES =
  'pass_on_confirm_change_who_pays_the_fees';
export const PASS_ON_CANCEL_CHANGE_WHO_PAYS_THE_FEES =
  'pass_on_cancel_change_who_pays_the_fees';
export const PASS_ON_VIEW_PRICING_BREAKDOWN =
  'pass_on_view_pricing_breakdown_on_input';
export const PASS_ON_HIDE_PRICING_BREAKDOWN =
  'pass_on_hide_pricing_breakdown_on_input';
export const PASS_ON_MANAGE_FEE_SETTINGS = 'pass_on_manage_fee_settings';

export const CREATE_COMMUNITY_FROM_PUBLIC_PAGE_FOOTER =
  'create_community_from_public_page_footer';

export const CONTACT_SUPPORT_FROM_PUBLIC_PAGE_FOOTER =
  'contact_support_from_public_page_footer';

// NEW MEMBER EXPERIENCE
export const MEMBER_TAB_CLICK_HOME = 'community_public_page_home_click';
export const MEMBER_TAB_CLICK_PRODUCT =
  'community_public_page_product_click';
export const MEMBER_TAB_CLICK_CHALLENGES =
  'community_public_page_challenges_click';
export const MEMBER_TAB_CLICK_EVENTS =
  'community_public_page_events_click';
export const MEMBER_TAB_CLICK_CHAT = 'community_public_page_chat_click';
export const MEMBER_TAB_CLICK_MEMBERS =
  'community_public_page_members_click';
export const MEMBER_TAB_CLICK_ABOUT = 'community_public_page_about_click';
export const UPCOMING_CARD_CLICK =
  'community_public_page_upcoming_card_click';
export const ONGOING_CARD_CLICK =
  'community_public_page_ongoing_card_click';
export const CHANGE_EVENT_TAB_FILTER =
  'community_public_page_change_event_filter';
export const MEMBER_EVENT_CARD_CLICK =
  'community_public_page_event_card_click';
export const MEMBER_NAVBAR_JOIN_COMMUNITY_CLICK =
  'community_public_page_navbar_join_community_click';
export const MEMBER_NAVBAR_LOGIN_CLICK =
  'community_public_page_navbar_login_click';
export const MEMBER_NAVBAR_DASHBOARD_CLICK =
  'community_public_page_navbar_dashboard_click';
export const MEMBER_PRODUCT_CARD_CLICK =
  'community_public_page_product_card_click';
export const MEMBER_CHALLENGE_CARD_CLICK =
  'community_public_page_challenge_card_click';
export const MEMBER_JOIN_COMMUNITY_CLICK =
  'community_public_page_join_community_cta_click';

// MAGIC REACH SCHEDULING
export const SCHEDULE_BUTTON_CLICK =
  'magic_reach_create_schedule_button_click';
export const SAVE_SCHEDULE_CLICK = 'magic_reach_save_schedule_click';
export const EDIT_SCHEDULE_CLICK = 'magic_reach_edit_schedule_click';
export const EDIT_SCHEDULED_MESSAGE_CLICK =
  'magic_reach_edit_scheduled_message_click';
export const DUPLICATE_SCHEDULED_MESSAGE_CLICK =
  'magic_reach_duplicate_scheduled_message_click';
export const SCHEDULE_MAGIC_REACH_CLICK =
  'magic_reach_schedule_magic_reach_click';
